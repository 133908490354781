<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="客户名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="账户状态">
            <a-select
              v-decorator="['effective', { initialValue: '1' }]"
              allow-clear
            >
              <a-select-option key="1" value="1">
                有效
              </a-select-option>
              <a-select-option key="0" value="0">
                无效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand" v-if="isShowSaleOption">
          <a-form-item label="责任销售">
            <a-select
              :loading="loadingSaleOptions"
              @focus="fetchSalesOptions"
              v-decorator="['sale_id']"
              allow-clear
            >
              <a-select-option
                v-for="user in salesOptions"
                :key="user.id"
                :value="user.id"
              >
                {{ user.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="签约主体">
            <a-select
              :loading="loadingContractingPartyOptions"
              show-search
              :filter-option="filterOption"
              v-decorator="['contracting_party_id']"
            >
              <a-select-option
                v-for="contracting_party in contractingPartyOptions"
                :key="contracting_party.id"
                :value="contracting_party.id"
              >
                {{ contracting_party.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findEffectiveSaleOptions } from '@/api/user'
import { findContractingPartyOptions } from '@/api/contracting_party'

export default {
  name: 'SearchAgent',
  data() {
    return {
      expand: false,
      salesOptions: [],
      loadingSaleOptions: false,
      form: this.$form.createForm(this, { name: 'agent_search' }),
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true
    }
  },
  computed: {
    isShowSaleOption() {
      return this.$store.getters.userRole !== 'sale' && this.$store.getters.userRole !== 'sale_assistant'
    }
  },
  created() {
    this.fetchContractingPartiesOption()
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    fetchSalesOptions() {
      if (this.salesOptions.length === 0) {
        findEffectiveSaleOptions().then((res) => {
          if (res.code === 0) {
            this.salesOptions = res.data
          }
          this.loadingSaleOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    fetchContractingPartiesOption() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    }
  }
}
</script>
