<template>
  <div>
    <search-agent @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="isShowNewModal = true" v-if="isShowMenu('agents/new')">新增</a-button>

        <update-agent-effective
          v-if="isShowMenu('agents/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <destroy-agent
          v-if="isShowMenu('agents/delete')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />

        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isShowMenu('agents/show')">
          <router-link :to="'/agents/' + record.id + '/info'"> {{ name }} </router-link>
        </div>
        <div v-else>{{ name }}</div>
      </div>

      <div slot="own_agents_count" slot-scope="own_agents_count, record">
        <div v-if="own_agents_count > 0 && isShowMenu('agents')">
          <a @click="showAgenciesModal(record.id)"> {{ own_agents_count }} </a>
        </div>

        <div v-else>
          {{ own_agents_count }}
        </div>
      </div>

      <div slot="vpdns_count" slot-scope="vpdns_count, record">
        <div v-if="vpdns_count > 0 && isShowMenu('agents')">
          <a @click="showVpdnsModal(record.id)"> {{ vpdns_count }} </a>
        </div>

        <div v-else>
          {{ vpdns_count }}
        </div>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增客户模态框 -->
    <new-agent
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑客户模态框 -->
    <edit-agent
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <agencies
      v-if="isShowAgenciesModal"
      :visible.sync="isShowAgenciesModal"
      :id="isShowAgentId"
    />

    <!-- vpdn分组详细信息 -->
    <vpdns v-if="isShowVpdnsModal" :visible.sync="isShowVpdnsModal" :id="isShowAgentId" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findAgents, exportAgents } from '@/api/agent'
import { hasPermission } from '@/utils/permission'
import SearchAgent from '@/views/agents/Search'
import { formatFloat } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentsList',
  components: {
    Pagination,
    SearchAgent,
    NewAgent: () => import('@/views/agents/New'),
    EditAgent: () => import('@/views/agents/Edit'),
    DestroyAgent: () => import('@/views/agents/Destroy'),
    UpdateAgentEffective: () => import('@/views/agents/UpdateEffective'),
    Agencies: () => import('@/views/agents/agencies/overview/index'),
    Vpdns: () => import('@/views/agents/vpdns/index')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      isShowEditModal: false,
      isShowAgenciesModal: false,
      isShowVpdnsModal: false,
      isShowAgentId: 0,
      selectedRowKeys: [],
      editingId: 0, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1'
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    columns() {
      return [
        // {
        //   title: '客户ID',
        //   dataIndex: 'id',
        //   fixed: 'left',
        //   width: 80
        // },
        {
          title: '客户名称',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 250,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号状态',
          dataIndex: 'effective'
        },
        {
          title: '信用额度',
          dataIndex: 'credit',
          customRender: formatFloat,
          scopedSlots: { customRender: 'credit' }
        },
        {
          title: '余额',
          dataIndex: 'balance',
          customRender: formatFloat,
          scopedSlots: { customRender: 'balance' }
        },
        {
          title: '类型',
          dataIndex: 'type'
        },
        {
          title: 'VPDN分组数',
          dataIndex: 'vpdns_count',
          sorter: true,
          sortOrder:
            this.sort.sort_field === 'vpdns_count' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'vpdns_count' }
        },
        {
          title: '后付权限',
          dataIndex: 'is_after_pay_enabled'
        },
        {
          title: '卡号总数',
          dataIndex: 'card_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'card_count' ? this.sort.sort_order : false
        },
        {
          title: '本月周期到期卡数',
          dataIndex: 'current_month_expire_card_count',
          sorter: true,
          sortOrder:
            this.sort.sort_field === 'current_month_expire_card_count'
              ? this.sort.sort_order
              : false
        },
        {
          title: '本月预计销卡数',
          dataIndex: 'current_month_expect_cancel_card_count',
          sorter: true,
          sortOrder:
            this.sort.sort_field === 'current_month_expect_cancel_card_count'
              ? this.sort.sort_order
              : false
        },
        {
          title: '企业客户数量',
          dataIndex: 'own_agents_count',
          scopedSlots: { customRender: 'own_agents_count' }
        },
        {
          title: '责任销售',
          dataIndex: 'sales_full_names'
        },
        {
          title: '签约主体',
          dataIndex: 'contracting_party'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    isHasEditPermission() {
      return hasPermission('agents/edit')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showEditModal(agentId) {
      this.editingId = agentId
      this.isShowEditModal = true
    },

    showAgenciesModal(agentId) {
      this.isShowAgentId = agentId
      this.isShowAgenciesModal = true
    },

    showVpdnsModal(agentId) {
      this.isShowAgentId = agentId
      this.isShowVpdnsModal = true
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign(
        {},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search
      )
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAgents(Object.assign({}, this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination.total_count = res.pagination.total_count
        this.loading = false
      })
    },

    exportData() {
      exportAgents(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style scoped></style>
